<template>
  <div class="meeting-room-wrap">
    <v-row justify="space-between" no-gutters>
      <v-col
        :class="{
          'justify-content-between': !$vuetify.breakpoint.smAndDown,
        }"
        class="d-flex align-center justify-space-between pt-3"
        cols="12"
      >
        <span class="heading-2"> Meeting Room </span>
        <PrimaryButton
          :block="$vuetify.breakpoint.smAndDown"
          :class="{ 'mt-2': $vuetify.breakpoint.smAndDown }"
          class="btn"
          color="success"
          height="38"
          left-icon="mdi-plus"
          text="New invite"
          @onClick="openInviteDialog"
        />
      </v-col>
    </v-row>
    <v-row v-if="meetingRooms.length" class="wrap-slider">
      <v-col :lg="12" :md="12" cols="12" sm="12">
        <ParticipantSlide :items="meetingRooms" />
      </v-col>
    </v-row>
    <router-view></router-view>
  </div>
</template>
<script>
import { mapActions, mapState } from "pinia";

import ParticipantSlide from "@/components/MeetingRoom/ParticipantSlide";
import PrimaryButton from "@/components/uikit/PrimaryButton.vue";
import { useMeetingRoomStore } from "@/pinia-store/meetingRoom";

export default {
  name: "MeetingRoom",
  components: {
    PrimaryButton,
    ParticipantSlide,
  },
  data() {
    return {
      slideSettings: {
        dots: false,
        arrows: true,
        centerMode: true,
        centerPadding: "20px",
        variableWidth: true,
      },
    };
  },
  computed: {
    ...mapState(useMeetingRoomStore, ["meetingRooms"]),
  },
  methods: {
    ...mapActions(useMeetingRoomStore, ["getMeetingRooms"]),
    ...mapActions(useMeetingRoomStore, ["setInviteDialog", "setOpenedMeetingRoom", "setOpenedListDialog"]),
    createdData(date) {
      return date && this.$moment(date).isValid() ? this.$moment(date).fromNow() : "";
    },
    openInviteDialog() {
      this.setOpenedListDialog(false);
      this.setOpenedMeetingRoom(false);
      this.setInviteDialog(true);
    },
  },
  async mounted() {
    await this.getMeetingRooms();
  },
};
</script>

<style lang="scss" scoped>
.meeting-room-wrap {
  > .row {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.wrap-slider {
  background: #eeeff7;
  padding: 0 32px;
}
</style>
