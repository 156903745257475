<template>
  <div class="participants-slide">
    <VueSlickCarousel v-if="items.length" v-bind="slideSettings">
      <template #prevArrow>
        <div class="prev-arrow">
          <img src="@/assets/icons/arrow.svg" />
        </div>
      </template>
      <template #nextArrow>
        <div class="next-arrow">
          <img src="@/assets/icons/arrow.svg" />
        </div>
      </template>
      <div v-for="room in items" :key="room.id" class="participants-slide__cell">
        <ParticipantBox :item="room" />
      </div>
    </VueSlickCarousel>
  </div>
</template>

<script>
import VueSlickCarousel from "vue-slick-carousel";

import ParticipantBox from "@/components/MeetingRoom/ParticipantBox.vue";

export default {
  name: "ParticipantSlide",
  data() {
    return {
      slideSettings: {
        dots: false,
        arrows: true,
        centerMode: false,
        centerPadding: "20px",
        variableWidth: true,
        infinite: false,
      },
    };
  },
  components: {
    ParticipantBox,
    VueSlickCarousel,
  },
  props: ["items"],
  computed: {},
  methods: {
    isActive(id) {
      return this.$route.params.roomId === id;
    },
    createdData(date) {
      return date && this.$moment(date).isValid() ? this.$moment(date).fromNow() : "";
    },
  },
};
</script>

<style lang="scss">
.participants-slide {
  background: #eeeff7;
  //gap: 16px;

  .slick-slide {
    //margin: 0 16px;
  }

  .prev-arrow {
    top: calc(50% - 14px);
    width: 28px;
    height: 28px;
    background: #ffffff;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.12);
    transform: matrix(-1, 0, 0, 1, 0, 0);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;

    img {
      height: 12px;
      width: 12px;
    }
  }

  .next-arrow {
    width: 28px;
    height: 28px;
    background: #ffffff;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.12);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;

    img {
      height: 12px;
      width: 12px;
    }
  }

  .slick-prev:before {
    color: #33343e;
    content: "";
  }

  .slick-next:before {
    color: #33343e;
    content: "";
  }

  &__cell {
    margin: 12px 22px;
    display: grid;
    grid-template-columns: 1fr 4fr;
    -webkit-column-gap: 12px;
    -moz-column-gap: 12px;
    column-gap: 12px;
  }

  &__title {
    padding-top: 15px;
    padding-bottom: 10px;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  &__description {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #91909c;
  }

  img {
    width: 200px;
    height: 200px;
    object-fit: cover;
  }

  .v-card__title {
    line-height: 1;
    padding: 8px 16px;
  }
}
</style>
